import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Img from "gatsby-image"
import NewsletterForm from "../components/NewsletterForm"

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark
        const siteTitle = this.props.data.site.siteMetadata.title
        const { previous, next } = this.props.pageContext
        const tags = post.frontmatter.tags

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                    canonical={post.frontmatter.canonical}
                />
                <section className="blog-posts-content-container">
                    <ul style={{ margin: 0, display: "flex" }}>
                        {tags.map(tag => (
                            <li key={tag} style={{ margin: 0 }}>
                                <Link
                                    to={`/tags/${tag}`}
                                    className="blog-posts-tag"
                                >
                                    #{tag}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <h1
                        style={{
                            marginTop: 16,
                            fontSize: "2.4rem",
                        }}
                    >
                        {post.frontmatter.title}
                    </h1>
                    <p
                        style={{
                            ...scale(-1 / 6),
                            display: `block`,
                            marginBottom: rhythm(1.5),
                            marginTop: -20,
                        }}
                    >
                        {post.frontmatter.date}
                        {!post.frontmatter.publishedFor && (
                            <span>
                                &nbsp; | &nbsp; {post.timeToRead} min read
                            </span>
                        )}
                    </p>
                    {post.frontmatter.feature_image && (
                        <Img
                            fluid={
                                post.frontmatter.feature_image.childImageSharp
                                    .fluid
                            }
                        />
                    )}
                    <article
                        dangerouslySetInnerHTML={{ __html: post.html }}
                        className="blog-posts-content-wrapper"
                    />
                    <hr
                        style={{
                            marginBottom: rhythm(1),
                        }}
                    />
                    <ul className="blog-post-nav">
                        <li>
                            {previous && (
                                <Link
                                    to={previous.fields.slug}
                                    rel="prev"
                                    className="read-btn read-btn-previous"
                                >
                                    <span className="sr-only">
                                        Previous Article:
                                    </span>
                                    &#171; &nbsp; {previous.frontmatter.title}
                                </Link>
                            )}
                        </li>
                        <li>
                            {next && (
                                <Link
                                    to={next.fields.slug}
                                    rel="next"
                                    className="read-btn read-btn-next"
                                >
                                    <span className="sr-only">
                                        Previous Article:
                                    </span>
                                    {next.frontmatter.title} &nbsp; &#187;
                                </Link>
                            )}
                        </li>
                    </ul>
                    <div class="newsletter-homepage">
                        <div class="newsletter-home-container newsletter-card">
                            <p>
                                I share what I learn while I navigate the deep
                                trenches of software development. Subscribe to
                                the newsletter!
                            </p>
                            <NewsletterForm />
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 140)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                feature_image {
                    publicURL
                    childImageSharp {
                        fluid {
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            aspectRatio
                            tracedSVG
                        }
                    }
                }
                tags
                canonical
                publishedFor
            }
            timeToRead
        }
    }
`
